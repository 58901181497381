<template>
  <div>
    <Header
      customPageName="Cadastro"
      :showMenuIcon="false"
      :showSearchIcon="false"
      :showBackButton="true"
      :smallContainer="true"
      class="page-anim1"
    />
    <section class="pt-2">
      <div class="container small">
        <h1 class="title text-blue my-1 section-title page-anim2">
          Cadastre-se
        </h1>
        <div class="input-holder labeled mt-3 page-anim1">
          <p class="text-desc text-brown text-bold">Selecione o seu país</p>
          <select v-model="selectedCountry" style="padding-left:0;">
            <option
              v-for="(country, index) in countries"
              :key="index"
              :value="country"
              >{{ country.nativeName }}</option
            >
          </select>
        </div>
        <transition enter-active-class="animated zoomIn">
          <button
            @click="nextStep"
            v-show="selectedCountry.nativeName"
            class="default bg-brown my-2 desktop4"
          >
            Avançar
          </button>
        </transition>
      </div>
    </section>
  </div>
</template>
<script>
import Header from "@/components/Header";
import Countries from "@/assets/countries.json";
export default {
  name: "PreCadastro",
  components: {
    Header,
  },
  data() {
    return {
      countries: [],
      selectedCountry: {}
    };
  },
  created() {
    this.countries = Countries;
  },
  computed: {
    nativeLanguage() {
      if (
        this.selectedCountry &&
        this.selectedCountry.languages &&
        this.selectedCountry.languages.length > 0 &&
        this.selectedCountry.languages[0].nativeName
      ) {
        return this.selectedCountry.languages[0];
      }
      return false;
    },
  },
  methods: {
    nextStep() {     
      var params = ""; 
      if(this.$route.query.renew === "y") {
        params = "?renew=y"
      }
      localStorage.selectedCountry = JSON.stringify(this.selectedCountry);
      if (this.selectedCountry.nativeName.toLowerCase() == "brasil") {       
        this.$router.push("/cadastro/nacional" + params);
      } else {
        this.$router.push("/cadastro/internacional" + params);
      }
    },
  },
};
</script>
